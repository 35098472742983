import React, {
  useEffect, useState, useRef, useMemo, 
} from 'react';
import { Button, Grid, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Canvas from '../../hooks/Canvas';
import backgroundImg from '../../images/background.png';
import proVideos from '../../videos/index';

function SelectProScreen() {
  const navigate = useNavigate();

  const [selectedPro, setSelectedPro] = useState('');
  const gridRef = useRef(null);
  const gridBoxRefs = useMemo(() => Array(8).fill(0).map((i) => React.createRef()), []);
  const videoFiles = proVideos;

  const [videoElements, setVideoElements] = useState([]);
  // const [videoFiles, setVideoFiles] = useState([]);
  const [canvasWidth, setCanvasWidth] = useState(1);
  const [canvasHeight, setCanvasHeight] = useState(1);
  
  const buildVideos = () => {
    if (videoFiles.length === 8) {
      const tempVideos = [];
      for (let i = 0; i < gridBoxRefs.length; i++) {
        if (gridBoxRefs[i].current) {
          const v = document.createElement('video');
          v.src = videoFiles[i].file;
          v.width = gridBoxRefs[i].current.offsetWidth;
          v.height = gridBoxRefs[i].current.offsetHeight;
          v.muted = true;
          v.autoPlay = false;
          v.controls = false;
          v.loop = false;
          v.setAttribute('webkit-playsinline', 'webkit-playsinline');
          v.setAttribute('playsInline', 'playsInline');
          tempVideos.push(v);
        }
        setCanvasWidth(gridRef.current.offsetWidth);
        setCanvasHeight(gridRef.current.offsetHeight);
        setVideoElements(tempVideos);
      }
    }
  };
  
  const start = () => {
    navigate('/capture', { state: { proSelect: selectedPro } });
  };

  const enableContinueButton = () => selectedPro !== '';

  const onProSelected = (proName) => {
    console.log(proName);

    setSelectedPro(proName);
  };
  
  useEffect(() => {

  }, []);

  return (
    <div style={{
      height: '100%',
    }}>
      <div style={{
        height: '70vh',
      }}>
        <h2
          className="txt__select-header"
        >
          Pick A Pro
        </h2>
        <img
          src={backgroundImg}
          alt="" />
      </div>
  
      <Grid
        ref={gridRef}
        container
        spacing={1}
        sx={{
          position: 'absolute',
          top: '12%',
        }}>
        {videoFiles.map((video, index) => ( 
          <Grid
            key={video.proName}
            className={video.proName + (selectedPro === video.proName ? ' selected' : ' unselected')}
            item
            xs={6}
            sx={{
            }}
            >
            <Box
              color="black"
              display="flex"
              justifyContent={video.alignment}
            >
              <div
                style={{
                  width: '52.5%',
                  height: '175px',
                }}
                onTouchEnd={() => {
                  console.log(video.proName);

                  onProSelected(video.proName);
                }}
                ref={gridBoxRefs[index]} />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="contained"
        color="primary"
        disabled={!enableContinueButton()}
        onTouchEnd={start}
        sx={{
          width: '80%',
          height: '7%',
          borderRadius: 8,
          textTransform: 'none',
          fontSize: '24px',
          lineHeight: '29px',
          fontWeight: 700,
          marginTop: '8%',
        }}>
        Continue
      </Button>
    </div>
  );
}

export default SelectProScreen;
