import * as React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// Video Import Example
// import attractLoopVideo from '../../videos/Attract_Loop_Mobile_sm_1080p.mp4';
import imagePromptDesktop from '../../images/ccs-hero.jpg';

function IdleScreen() {
  const navigate = useNavigate();
  const start = () => navigate('/ad');
  
  return (
    <div className="App__inner">
      <img src={imagePromptDesktop} className="image__cover cover-image__idlescreen" alt="prompt__desktop" />

      <Button
        variant="contained"
        color="primary"
        onTouchEnd={start}
        className="button__start-button"
        sx={{
          borderRadius: 8,
          textTransform: 'none',
          fontSize: '24px',
          lineHeight: '29px',
          fontWeight: 700,
          paddingBottom: '30px',
        }}>
        Tap to discover something unexpected
      </Button>
    </div>
  );
}

export default IdleScreen;

/* Example Video Full-Screen
<div style={{
  height: '100%',
  backgroundColor: 'white',
}}>
  <video
    id="idle-video"
    muted
    playsInline
    autoPlay
    width="100%"
    loop 
    style={{
      minHeight: '660px',
    }}>
    <source
      src={attractLoopVideo}
      type="video/mp4" />
  </video>
</div> */
