import * as React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import backgroundImg from '../../images/background.png';
import imagePromptDesktop from '../../images/ccs-hero.jpg';

function TakeawayScreen() {
  const navigate = useNavigate();
  const start = () => navigate('/');

  return (
    <div className="App__inner">
      <div>
        <h2
          className="txt__select-header"
          style={{
            fontSize: '62px',
          }}
        >
          THANK
          <br />
          YOU!
        </h2>

        <img src={imagePromptDesktop} className="image__cover cover-image__idlescreen" alt="prompt__desktop" />
      </div>
    </div>
  );
}

export default TakeawayScreen;
