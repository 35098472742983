import arroyoVid from './0_Christian_Arroyo_L_Mobile_sm_1080p.mp4';
import hernandezVid from './1_Enrique_Hernandez_L_Mobile_sm_1080p.mp4';
import jansenVid from './2_Kenley_Jansen_L_Mobile_sm_1080p.mp4';
import deversVid from './3_Rafael_Devers_L_Mobile_sm_1080p.mp4';
import duvallVid from './4_Adam_Duvall_R_Mobile_sm_1080p.mp4';
import verdugoVid from './5_Alex_Verdugo_R_Mobile_sm_1080p.mp4';
import turnerVid from './6_Justin_Turner_R_Mobile_sm_1080p.mp4';
import casaVid from './7_Triston_Casas_R_Mobile_sm_1080p.mp4';

const proVideos = [
  {
    order: 0,
    file: arroyoVid,
    proName: 'arroyo',
    alignment: 'flex-start',
    image: './images/0_Christian_Arroyo_Button.png',
  },
  {
    order: 1,
    file: verdugoVid,
    proName: 'verdugo',
    alignment: 'flex-end',
    image: './images/5_Alex_Verdugo_Button.png',
  },
  {
    order: 2,
    file: hernandezVid,
    proName: 'hernandez',
    alignment: 'flex-start',
    image: './images/1_Enrique_Hernandez_Button.png',
  },
  {
    order: 3,
    file: turnerVid,
    proName: 'turner',
    alignment: 'flex-end',
    image: './images/6_Justin_Turner_Button.png',
  },
  {
    order: 4,
    file: jansenVid,
    proName: 'jansen',
    alignment: 'flex-start',
    image: './images/2_Kenley_Jansen_Button.png',
  },
  {
    order: 5,
    file: casaVid,
    proName: 'casa',
    alignment: 'flex-end',
    image: './images/7_Triston_Casas_Button.png',
  },
  {
    order: 6,
    file: deversVid,
    proName: 'devers',
    alignment: 'flex-start',
    image: './images/3_Rafael_Devers_Button.png',
  },
  {
    order: 7,
    file: duvallVid,
    proName: 'duvall',
    alignment: 'flex-end',
    image: './images/4_Adam_Duvall_Button.png',
  },
];

export default proVideos;
