import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

function UserInfoScreen() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formErrors, setFormErrors] = useState('');
  const errors = {};

  const dataURItoBlob = (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = unescape(dataURI.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };
  
  const validateEmail = (values) => {
    if (!values) {
      errors.email = 'Email Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values)) {
      errors.email = 'Invalid email address';
    } else {
      errors.email = false;
    }
  
    console.log(errors);
    setFormErrors(errors);
    return errors;
  };
  
  const validatePhone = (values) => {  
    if (!values) {
      errors.phone = false;
    } else if (!(values.match('[0-9]{10}'))) {
      errors.phone = 'Please enter a complete Phone Number';
    } else {
      errors.phone = false;
    }
  
    console.log(errors);
    setFormErrors(errors);
    return errors;
  };

  const submitForm = (event) => {
    event.preventDefault();
  
    const formDataSet = new FormData(event.target);
    const phoneData = formDataSet.get('phone');
    const emailData = formDataSet.get('email');

    const validateEmailThing = validateEmail(emailData);
    const validatePhoneThing = validatePhone(phoneData);

    if (!validatePhoneThing.phone) {
      formDataSet.set('phone', phoneData);
    } else {
      console.log('phoneinvalid');
      // console.log(formData);
  
      return;
    }
  
    if (!validateEmailThing.email) {
      formDataSet.set('email', emailData);
    } else {
      console.log('emailinvalid');
      // console.log(formData);
  
      return;
    }

    console.log('formErrors');
    console.log(formErrors);

    if (!validateEmailThing.email && !validatePhoneThing.phone) {
      formDataSet.set('firstname', 'empty');
      formDataSet.set('lastname', 'empty');

      const blobImage = dataURItoBlob(state.imageUri);

      formDataSet.set(state.imageName, blobImage);
    }
  
    const requestOptions = {
      method: 'POST',
      body: formDataSet,
    };

    console.log(requestOptions);
    
    // https://httpbin.org/anything
    // https://mvp.live/endpoint/be88117e-9402-450a-aa47-69e889972917
    fetch('https://mvp.live/endpoint/be88117e-9402-450a-aa47-69e889972917', requestOptions)
      .then((response) => {
        console.log(response);
  
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // console.log(data.status);

        if (data.status === 0) {
          navigate('/takeaway');
        }
      });  
  };

  console.log(formErrors);

  return (
    <div className="App__inner">
      <form 
        onSubmit={submitForm}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <p
          className="text__large-header">
          Try new
          <br /> 
          Coca-Cola® Spiced
          <br />
          in stores
        </p>
        <p
          className="text__mid-header">
          Enter details to receive
          <br />
          your meme
        </p>
        <TextField
          color="primary"
          label="Email*"
          id="email" 
          name="email"
          error={!formErrors.email}
          helperText={!formErrors.email ? '' : formErrors.email}
          sx={{
            marginBottom: '15px',
            width: '80%',
          }} />
        <TextField
          color="primary"
          label="Phone"
          id="phone" 
          name="phone"
          error={!formErrors.phone}
          helperText={!formErrors.phone ? '' : formErrors.phone}
          sx={{
            marginBottom: '15px',
            width: '80%',
          }} />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{
            width: '80%',
            height: '7%',
            borderRadius: 8,
            textTransform: 'none',
            fontSize: '14.45px',
            lineHeight: '45px',
            fontWeight: 700,
          }}>
          Tap to 
          <br />
          Send
        </Button>
      </form>
    </div>
  );
}

export default UserInfoScreen;
