import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IdleScreen from './routes/Idle-Screen';
import AdScreen from './routes/Ad-Screen';
import SelectProScreen from './routes/Select-Pro-Screen';
import CaptureScreen from './routes/Capture-Screen';
import ReviewScreen from './routes/Review-Info-Screen';
import TakeawayScreen from './routes/Takeaway-Screen';
import UserInfoScreen from './routes/User-Info-Screen';

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<IdleScreen />} />
        <Route
          path="idle"
          element={<IdleScreen />} />
        <Route
          path="ad"
          element={<AdScreen />} />
        <Route
          path="select"
          element={<SelectProScreen />} />
        <Route
          path="capture"
          element={<CaptureScreen />} />
        <Route
          path="review"
          element={<ReviewScreen />} />
        <Route
          path="userinfo"
          element={<UserInfoScreen />} />
        <Route
          path="takeaway"
          element={<TakeawayScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
